import axios from "axios";
import BASE_URL from "../config/host";
import LMS_URL from "../config/lms";
export const emailVerify = async (body: any) => {
  let formData = new FormData();
  formData.append("wstoken", body.wstoken);
  formData.append("wsfunction", body.wsfunction);
  formData.append("moodlewsrestformat", body.moodlewsrestformat);
  formData.append("email", body.email);
  const details = await axios({
    method: "post",
    url: LMS_URL + "/webservice/rest/server.php",
    data: formData,
  });

  return details.data;
};

export const register = async (body: any) => {
  // program_id=MjQ2

  const details = await axios({
    method: "post",
    url: BASE_URL + "/index.php/wp-json/users/register",
    data: body,
  });

  return details.data;
};

export const placeOrder = async (body: any) => {
  // program_id=MjQ2
  const orderData = await axios({
    method: "post",
    url: BASE_URL + "/index.php/wp-json/place/oder",
    data: body,
  });
  console.log("orderData22",orderData)
  return orderData.data;
};

export const getUserData = async (body: any) => {
  // program_id=MjQ2
  const userEmailData = await axios({
    method: "post",
    url: BASE_URL + "/index.php/wp-json/verify/user",
    data: body,
  });
  console.log("orderData22111",userEmailData.data)
  return userEmailData.data;
};

export const getResponce = async (body: any) => {
  // program_id=MjQ2

  const orderData = await axios({
    method: "post",
    url: BASE_URL + "/index.php/wp-json/details_by/order_id",
    data: body,
  });

  return orderData.data;
};

export const moodleRegister = async (body: any) => {
  const registerData = await axios({
    method: "post",
    url: LMS_URL + "/webservice/rest/server.php",
    data: body,
  });

  return registerData.data;
};

export const enroll = async (body: any) => {
  const enrollData = await axios({
    method: "post",
    url: LMS_URL + "/webservice/rest/server.php",
    data: body,
  });
  return enrollData.data;
};

export const orderResponse = async (body: any) => {
  const dataOne = await axios({
    method: "post",
    url: BASE_URL + "/index.php/wp-json/order/response",
    data: body,
  });
  return dataOne.data;
};
