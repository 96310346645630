//const BASE_URL = "https://learningtlms.in/gt";
// const BASE_URL = "https://learningtlms.in/gt";

const BASE_URL = "https://api.learningt.com";
// const BASE_URL = "https://uat.gtacademy.in/gtacademy/gt";
export const THIRD_PARTY_BASE_URL = "https://thirdpartyapi.extraaedge.com";
// const BASE_URL = "https://rwtpl.com/demov/gt";
//https://uat.gtacademy.in/gtacademy

export const leadsURL = "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?AccessKey='u$rcf1486b4c477ffb354b2ab3f5acdeaec'&secretKey='85f0a65c60be1f617083cfead28347944b1ffcf3'";
export default BASE_URL;

//UAT//
// export const TOKEN_USER_LOGIN = "21c61112796e8eafb4e65c38fc986a2b"; 
// export const TOKEN_USER_REG = "e479570e79e47ee23b96c3beead1c915"; 
// export const TOKEN_EMAIL_VERIFY = "828b35831bf67e7b6c01d22ff334b0f3"; 
// export const TOKEN_COURSE_ENROLL = "66687209e3ab7e48a7c78a68f103aadf"; 
//UAT//


//LIVE//
export const TOKEN_USER_LOGIN = "44774588faa80db89fd429cb80ab2ac2"; 
export const TOKEN_USER_REG = "632f3b4d0b5f54bc6281614d1867bbf7"; 
export const TOKEN_EMAIL_VERIFY = "796dd4d320dc86a49529bc46f9c6837e"; 
export const TOKEN_COURSE_ENROLL = "980732e816007178d4964611a1960297"; 
//LIVE//
