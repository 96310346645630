import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams} from "react-router-dom";
import {
  emailVerify,
  getUserData,
  orderResponse,
  placeOrder,
  register,
} from "../../../actions/payment";
import * as https from "https";
import PreLoad from "../../../shared/PreLoad";
import PaytmChecksum from "../Register/paytmChecksum";
import { LoginSocialGoogle, IResolveParams } from "reactjs-social-login";
import axios from "axios";
import { exit } from "process";
import BASE_URL from "../../../config/host";
import TOKEN_EMAIL_VERIFY from "../../../config/host";
declare const window: any;
const SignupForm = (data: any) => {
  const sessionpassword = localStorage.getItem("password") || "";
  const sessionusername = localStorage.getItem("username") || "";
  const loginData =
    localStorage.getItem("loginData") &&
    JSON.parse(localStorage.getItem("loginData") || "");
  const phoneno =localStorage.getItem("phone");

  const [isCorporate, setIsCorporate] = useState(1);
  const [username, setUsername] = useState(loginData?.name);
  const [email, setEmail] = useState(loginData?.email);
  const [password, setPassword] = useState(sessionpassword);
  // const [sessionPhone, setSessionPhone] = useState(loginData? loginData?.phone:null);
// console.log("sessionPhone----"+sessionPhone);
  const [phone, setPhone] = useState(phoneno!);
  const [phonenumber, setPhonenumber] = useState(loginData?.phone);
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [experience, setExperience] = useState("");
  const [qualification, setQualification] = useState("");
  const [hireFrom, setHireFrom] = useState("");
  const [address, setAddress] = useState("");
  const signUpData = useSelector((state: any) => state.authData.signUpData);
  const [isSuccess, setSeccuess] = useState(false);
  const [isError, setError] = useState(false);
  const [isMessage, setMessage] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const appliedCoupon = data?.appliedCoupon;
  const totalPrice = data?.totalPrice;
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [firstnameerror, setfirstnameerror] = useState("");
  const [lastnameerror, setlastnameerror] = useState("");
  const [resInfo, setInfo] = useState({
    ORDER_ID: "",
    CUST_ID: "",
    CALLBACK_URL: "",
    CHECKSUMHASH: "",
    TXN_AMOUNT: 1,
  });

  const [isGST, setisGst] = useState(false);
  const [gstNumber, setGTINumber] = useState("");
  const [tanNumber, setTanNumber] = useState("");
  const [usernameerror, setusernameerror] = useState("");
  const [emailerror, setemailerror] = useState("");
  const [phoneerror, setphoneerror] = useState("");
  const [passworderror, setpassworderror] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();
  const [width, setWidth] = useState(0);
  const [decodedData, setDecodedData] = useState<any>(null);
  const [verifyMyEmail, setVerifyMyEmail] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState({
    token: "",
    order: "",
    mid: "",
    amount: 1,
  });
  const [loading, setLoading] = useState(false);

  const initialize = (data: any) => {
    setemailerror("");
    setLoadData(false);
    let orderId = data.ORDER_ID;

    // Sandbox Credentials
    let mid = "GranTT61417448852924"; // Merchant ID
    let mkey = "Ol!S9P5kwLjCoyys"; // Merhcant Key
    var paytmParams = {
      body: {},
      head: {},
    };

    paytmParams.body = {
      requestType: "Payment",
      mid: mid,
      websiteName: "WEBSTAGING",
      orderId: orderId,
      callbackUrl: data.CALLBACK_URL,
      txnAmount: {
        value: data.TXN_AMOUNT,
        currency: "INR",
      },
      userInfo: {
        custId: data.CUST_ID,
      },
    };

    PaytmChecksum.generateSignature(
      JSON.stringify(paytmParams.body),
      mkey
    ).then(function (checksum: any) {
      paytmParams.head = {
        signature: checksum,
      };

      var post_data = JSON.stringify(paytmParams);

      var options = {
        /* for Staging */ hostname: "securegw.paytm.in" /* for Production */,

        // hostname: 'securegw.paytm.in',

        port: 443,
        path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Content-Length": post_data.length,
        },
      };

      var response = "";
      var post_req = https.request(options, function (post_res: any) {
        post_res.on("data", function (chunk: any) {
          response += chunk;
        });
        post_res.on("end", function () {
          // res.json({data: JSON.parse(response), orderId: orderId, mid: mid, amount: amount});
          makePayment(
            orderId,
            JSON.parse(response).body.txnToken,
            data.TXN_AMOUNT
          );
          setPaymentData({
            ...paymentData,
            token: JSON.parse(response).body.txnToken,
            order: orderId,
            mid: mid,
            amount: data.TXN_AMOUNT,
          });
        });
      });

      post_req.write(post_data);
      post_req.end();
    });

    //makePayment();
  };

  const makePayment = (orderId: any, token: any, amount: any) => {
    setLoading(true);
    var config = {
      root: "",
      style: {
        bodyBackgroundColor: "#fafafb",
        bodyColor: "",
        themeBackgroundColor: "#0FB8C9",
        themeColor: "#ffffff",
        headerBackgroundColor: "#284055",
        headerColor: "#ffffff",
        errorColor: "",
        successColor: "",
        card: {
          padding: "",
          backgroundColor: "",
        },
      },
      data: {
        orderId: orderId,
        token: token,
        tokenType: "TXN_TOKEN",
        amount: amount /* update amount */,
      },
      payMode: {
        labels: {},
        filter: {
          exclude: [],
        },
        order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
      },
      website: "WEBSTAGING",
      flow: "DEFAULT",
      merchant: {
        mid: "GranTT61417448852924",
        redirect: false,
      },
      handler: {
        transactionStatus: async function transactionStatus(
          paymentStatus: any
        ) {
          if (paymentStatus.STATUS === "TXN_SUCCESS") {
            await orderResponse({
              order_id: orderId,
              payment_status: "wc-completed",
            });
            localStorage.setItem("username", email);
            window.location.href = "/payment?ORDER_ID=" + orderId;
            return;
          } else {
            await orderResponse({
              order_id: orderId,
              payment_status: "wc-failed",
            });
          }

          setLoading(false);
        },
        notifyMerchant: function notifyMerchant(eventName: any, data: any) {
          setLoading(false);
        },
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      // initialze configuration using init method
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          // after successfully update configuration invoke checkoutjs
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error: any) {
          console.log("Error => ", error);
        });
    }
  };

  const pay = async () => {
    setLoadData(true);
    const cartData = JSON.parse(localStorage.getItem("cartData") || "");
    let orderdetails = [];
    let cart_data = [];
    for (let i = 0; i < cartData.length; i++) {
      orderdetails.push({
        program_id: cartData[i]?.program_id,
        program_title: cartData[i]?.title,
        program_price: cartData[i]?.program_fees,
      });
      if (cartData[i]?.level && cartData[i]?.level.length > 0) {
        for (let k = 0; k < cartData[i]?.level[0].selected.length; k++) {
          cart_data.push(cartData[i]?.level[0].selected[k]);
        }
      } else {
        cart_data.push(cartData[i]?.program_id);
      }
    }
    
    const verify = await emailVerify({
      // wstoken : TOKEN_EMAIL_VERIFY,
      wstoken: "796dd4d320dc86a49529bc46f9c6837e",
      // wstoken: "828b35831bf67e7b6c01d22ff334b0f3", //UAT email verification
      wsfunction: "user_email_verification",
      moodlewsrestformat: "json",
      email: email,
    });
    console.log("verifyy",verify);
    if(loginData==null){
    if (!email) {
      setemailerror("Please enter your email address");
      setLoadData(false);
      return false;
    }

    if (phone) {
      phoneValidation();
     
    }else{
      setphoneerror("Please enter your mobile number");
      setLoadData(false);
      return false;
      // console.log("TTTTTTTTTTTTT",res);
    }
    // if (!username) {
    //   setusernameerror("Please enter your full name");
    //   setLoadData(false);
    //   return false;
    // }
    if (!firstName) {
      setfirstnameerror("Please enter your first name");
      setLoadData(false);
      return false;
    }
    if (!lastName) {
      setlastnameerror("Please enter your last name");
      setLoadData(false);
      return false;
    }
    if(phone==""){
      // setphoneerror("Please enter your mobile number");
      localStorage.setItem('phone', '');
      setLoadData(false);
      return false;
    }
  }
    

    if(verify.status === "user not exist"){
      const jsondata = {
        user_details: {
          username: email,
          email: email,
          password: password,
          mobile: phone,
          address: address,
          gst_number: gstNumber,
          tan_number: tanNumber,
          registration_type: "web",
        },
      };
      try {
        const reg = await register(jsondata);
        localStorage.setItem("password", password);
        localStorage.setItem("email", email);
        localStorage.setItem("firstname", firstName);
        localStorage.setItem("lastname", lastName);
        let finalCartData: any[] = [];
        Array.isArray(cart_data) &&
          cart_data.map((item) => {
            finalCartData.push({ id: item, qty: 1 });
          });

        const info = await placeOrder({
          user_id: reg?.data.user_login,
          cart_data: finalCartData,
          coupon_code: appliedCoupon,
        });
        setInfo(info.data);
        initialize(info.data);
        // window.location.reload();
        //
      } catch (error: any) {
        if (
          Array.isArray(error?.response?.data) &&
          error?.response?.data?.message[0]?.username
        ) {
          setusernameerror(error.response.data?.message[0]?.username);
        } else {
          setusernameerror("");
        }
        if (error.response?.data?.message[0]?.mobile) {
          setphoneerror(error.response.data?.message[0]?.mobile);
        } else {
          setphoneerror("");
        }
        if (error.response?.data?.message[0].email) {
          setemailerror(error.response.data?.message[0].email);
        } else {
          if (error.response?.data?.message[0].email_exists) {
            localStorage.setItem("firstname", firstName);
            localStorage.setItem("lastname", lastName);
            // setemailerror(error.response.data?.message[0].email_exists);
            // toast.error('This email id already registered. Please make payment first.')
            let finalCartData: any[] = [];
            Array.isArray(cart_data) &&
              cart_data.map((item) => {
                finalCartData.push({ id: item, qty: 1 });
              });

            const info = await placeOrder({
              user_id: email,
              cart_data: finalCartData,
              coupon_code: appliedCoupon,
            });
            setInfo(info.data);
            initialize(info.data);
            // alert("11");
            // toast.error("Please make payment first"); 
            // navigate('/login');
          } else {
            setemailerror("");
          }
        }
        if (error.response?.data?.message[0].password) {
          setpassworderror(error.response?.data?.message[0].password);
        } else {
          setpassworderror("");
        }
        setLoadData(false);
      }
    }else{
      if(verify.status === "success" && loginData==null){
        toast.error('This email id already registered. Please login and try"')
        localStorage.setItem("email",email);
        localStorage.setItem("firstname", firstName);
        localStorage.setItem("lastname", lastName);
        navigate('/login');
      }else{
        let finalCartData: any[] = [];
        Array.isArray(cart_data) &&
          cart_data.map((item) => {
            finalCartData.push({ id: item, qty: 1 });
          });

        const info = await placeOrder({
          user_id: email,
          cart_data: finalCartData,
          coupon_code: appliedCoupon,
        });
        setInfo(info.data);
        initialize(info.data);
      }
    }
    
  };

 
  function getUsersData(user_email:any) {
    const email = {user_email : user_email}
    var fdata = new FormData();
fdata.append('user_email', user_email);
  return fetch(BASE_URL + "/index.php/wp-json/verify/user", {
    method: 'POST',
    body: fdata
  }).then(data => {
    return data.status;
    console.log("******************",data.status);
  })
}

  useEffect(() => {
    if (signUpData && signUpData.code === 200) {
      toast.success(signUpData.message);
    } else {
      toast.error(signUpData.message);
    }
  }, [signUpData]);

  useEffect(() => {
    if (isSuccess === true) {
      toast.success(isMessage);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError === true) {
      toast.error(isMessage);
    }
  }, [isError]);

  useEffect(() => {
    setSeccuess(false);
    setError(false);
  }, []);

  const isCheck = (e: any) => {
    if (e) {
      setIsCorporate(1);
    } else {
      setIsCorporate(2);
    }
    setisGst(e);
  };

  const handlePasswordChange = (event: any) => {
    const { value } = event.target;
    setPassword(value);
    setIsValid(validatePassword(value));
  };

  const validatePassword = (password: any) => {
    const minLength = 8;
    const specialCharactersRegex =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    return (
      password.length >= minLength && specialCharactersRegex.test(password)
    );
  };

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);
  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${profile?.access_token}`
  //     )
  //     .then(function (response) {
  //       // handle success
  //       setDecodedData(response?.data);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     });
  // }, [profile]);

  //for googlr login
  // useEffect(() => {
  //   decodedData &&
  //     axios
  //       .post(`https://learningtlms.in/gt/index.php/wp-json/social/login`, {
  //         email: decodedData?.email,
  //         username: decodedData?.name,
  //       })
  //       .then((res: any) => {
  //         window.location.href = "/checkout";
  //         localStorage.setItem("loginData", JSON.stringify(res?.data?.data));
  //         localStorage.setItem("username", res?.data?.data?.display_name);
  //         // localStorage.setItem("password", password);
  //         toast.success("Successfully logged in");
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  // }, [decodedData]);

  function logScreenWidth() {
    setWidth(window.innerWidth);
  }
  window.addEventListener("resize", logScreenWidth);
  useEffect(() => {
    logScreenWidth();
  }, []);

  function phoneValidation() {
    // loginData.phone=phone;
    // localStorage.setItem('loginData', JSON.stringify(loginData));
    const regex = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
                    // const test:any =  regex.test(e);    
    if(phone!=null){                   
    if (regex.test(phone)) {
      if(phone.length==10){
        setPhone(phone)
        // loginData.phone=phone;
      localStorage.setItem('phone', phone);
        setphoneerror("");
        // return true;
      } else {
          setphoneerror("Please put 10  digit mobile number");
          setLoadData(false);
          setPhone("")
          return false;
          
      }
    }
    else {
      
      setphoneerror("Please enter valid mobile number");
      setLoadData(false);
      setPhone("")
      loginData.phone="";
      localStorage.setItem('loginData', JSON.stringify(loginData));
      return false;
    }}
  }
  return (
    <>
      <div className="registration-container h-100">
        <form className="course-reg-form" action="" style={{ margin: "auto" }}>
          <div
            className="form-heading"
            style={{
              // display: width > 614 ? "flex" : "block",
              display: "flex",
              flexDirection: "column",
              // justifyContent: width > 614 ? "left" : "center",
              // justifyContent: "left",
              // alignItems: "center",
              width: "273px",
              // border: "1px solid black",
            }}
          >
            { loginData == null?
            <div
              style={{
                // textAlign: "center",
                fontSize: "20px",
                fontWeight: "700",
                color: "#242227",
                //   padding: "0px 0px 10px 0px",
              }}
            >
              <p>Register / Sign up</p>
            </div>:''}

            {/* <div style={{ marginBottom: "10px" }}>
              <LoginSocialGoogle
                isOnlyGetToken
                // scope={"https://www.googleapis.com/auth/userinfo.email"}
                client_id={
                  "978577839819-2pgqe46d7b2sngptjp8lv5onophe1qs1.apps.googleusercontent.com" ||
                  ""
                }
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }: IResolveParams) => {
                  setProvider(provider);
                  setProfile(data);
                }}
                onReject={(err) => {
                  console.log(err);
                }}
              >
                <button className="btn btn-outline-secondary w-100">
                  <img
                    src="/google-logo.png"
                    height={"25px"}
                    width={"25px"}
                    style={{ marginRight: "5px" }}
                  />
                  Continue with Google
                </button>
              </LoginSocialGoogle>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                paddingTop: "10px",
              }}
            >
              <span
                style={{
                  width: "100px",
                  height: "1.5px",
                  backgroundColor: "#999",
                }}
              ></span>
              <span
                style={{ fontSize: "17px", fontWeight: "500", color: "#777" }}
              >
                OR
              </span>
              <span
                style={{
                  width: "100px",
                  height: "1.5px",
                  backgroundColor: "#999",
                }}
              ></span>
            </div> */}
          </div>

          { loginData == null &&
          <div className="row">
            <div className="col-lg-6 ">
              <div className="row">
              <div className="form-floating mb-3 col-6">
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="First Name*"
                  value={firstName}
                  onChange={(e) => setFirstname(e.target.value)}
                />
                <label htmlFor="fullname" style={{paddingLeft:'12px'}}>
                First Name <span>*</span>
                </label>
                {firstnameerror && (
                  <p style={{ color: "red" }}>{firstnameerror}</p>
                )}
              </div>

              <div className="form-floating mb-3 col-6">
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  placeholder="Last Name*"
                  value={lastName}
                  onChange={(e) => setLastname(e.target.value)}
                />
                <label htmlFor="fullname" style={{paddingLeft:'12px'}}>
                  Last Name <span>*</span>
                </label>
                {lastnameerror && (
                  <p style={{ color: "red" }}>{lastnameerror}</p>
                )}
              </div></div>
            </div>
            <div className="col-lg-6">
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control"
                  id="mobile"
                  placeholder="Mobile No.*"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label htmlFor="mobile">
                  Mobile No <span>*</span>
                </label>
                {phoneerror && <p style={{ color: "red" }}>{phoneerror}</p>}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="emailid"
                  placeholder="Email ID*"
                  value={email?.toLocaleLowerCase()}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="emailid">
                  Email ID <span>*</span>
                </label>
                {emailerror && <p style={{ color: "red" }}>{emailerror}</p>}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  placeholder="Enter password"
                  id="password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  style={{ borderColor: isValid ? "initial" : "red" }}
                  // onChange={e => setPassword(e.target.value)}
                />
                <label htmlFor="password">
                  Password <span>*</span>
                </label>
                {passworderror && (
                  <p style={{ color: "red" }}>{passworderror}</p>
                )}
                {!isValid && (
                  <p style={{ color: "red" }}>
                    Password must be at least 8 characters with at least 1
                    uppercase letter, 1 lowercase letter, 1 digit, and 1 special
                    character
                  </p>
                )}
              </div>
            </div>

            <div className="col-lg-12">
              <div className="program-reg-type mb-1">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="myCheckbox"
                    onChange={(e) => isCheck(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="myCheckbo">
                    Need a GST Invoice
                  </label>
                </div>
              </div>
            </div>
            {isGST && (
              <div className="corporate-type-option">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="gstin"
                        placeholder="GSTIN Number*"
                        value={gstNumber}
                        onChange={(e) => setGTINumber(e.target.value)}
                      />
                      <label htmlFor="gstin">GSTIN Number </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="tanno"
                        placeholder="TAN Number*"
                        value={tanNumber}
                        onChange={(e) => setTanNumber(e.target.value)}
                      />
                      <label htmlFor="tanno">TAN Number (N/A)</label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-floating mb-3">
                    <textarea
                      className="form-control"
                      id="address"
                      placeholder="Address*"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <label htmlFor="address">Address</label>
                  </div>
                </div>
              </div>
            )}

            {/* <div
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100px",
                  backgroundColor: "gray",
                  height: "2px",
                }}
              ></div>
              OR
              <div
                style={{
                  width: "100px",
                  backgroundColor: "gray",
                  height: "1px",
                }}
              ></div>
            </div> */}

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                gap: "20px",
                justifyContent: width > 614 ? "left" : "center",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="btn btn-dark btn-lg py-3 px-5"
                style={{ minWidth: "270px" }}
                onClick={() => pay()}
              >
                {loadData && <i className="fa fa-sync fa-spin"></i>} &nbsp;
                Proceed to Pay
              </button>
            </div>

            <div style={{ textAlign: "left", marginTop: "15px" }}>
              <span>Already an existing user?</span>
              <Link to={"/login"}>
                {" "}
                <span
                  style={{
                    fontSize: "17px",
                    color: "#4f2d7f",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Login
                </span>
              </Link>
            </div>
          </div>}
          {/* {sessionPhone} */}
         {(loginData!=null) && (loginData.phone=='' || loginData.phone==null)  &&
        //  {sessionPhone=="" &&
         <div className="col-lg-6">
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control"
                  id="mobile"
                  placeholder="Mobile No.*"
                  value={phone}
                  onChange={(e) => {
                    // setPhonenumber(e.target.value)
                    setPhone(e.target.value);
                    
                  }}
                />
                <label htmlFor="mobile">
                  Mobile No <span>*</span>
                </label>
                {phoneerror && <p style={{ color: "red" }}>{phoneerror}</p>}
              </div>
              <div
              style={{
                marginTop: "10px",
                display: "flex",
                gap: "20px",
                justifyContent: width > 614 ? "left" : "center",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="btn btn-dark btn-lg py-3 px-5"
                style={{ minWidth: "270px" }}
                onClick={() => pay()}
              >
                {loadData && <i className="fa fa-sync fa-spin"></i>} &nbsp;
                Proceed to Pay
              </button>
            </div>
            </div>
            }
            {loginData!=null && (loginData.phone!='' )  &&
              <div
            style={{
              marginTop: "10px",
              display: "flex",
              gap: "20px",
              justifyContent: width > 614 ? "left" : "center",
              alignItems: "center",
            }}
            >
            <button
              type="button"
              className="btn btn-dark btn-lg py-3 px-5"
              style={{ minWidth: "270px" }}
              onClick={() => pay()}
            >
              {loadData && <i className="fa fa-sync fa-spin"></i>} &nbsp;
              Proceed to Pay
            </button>
          </div>}
          {/* {loadData && <PreLoad loadData={loadData} />} */}
        </form>
      </div>

      <ToastContainer />
    </>
  );
};

export default SignupForm;
