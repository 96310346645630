import React, { useCallback, useEffect, useState } from "react";
import { loginuser } from "../actions/login";
import { toast } from "react-toastify";
import { LoginSocialGoogle, IResolveParams } from "reactjs-social-login";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import TOKEN_USER_REG from "../config/host";
const NewSignUp = () => {
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();
  const [formData, setFormData] = useState<any>({
    wsfunction: "local_participant_create_users",
    // wstoken: TOKEN_USER_REG,
    wstoken: "632f3b4d0b5f54bc6281614d1867bbf7",
    // wstoken: "e479570e79e47ee23b96c3beead1c915", //UAT registration
    username: "",
    // moodlewsrestformat: "json",
  });
  const [decodedData, setDecodedData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setFormData((prev: any) => ({ ...prev, username: prev?.email }));
  }, [formData]);

  const signUp = async () => {
    setLoading(true);
    // let formData = new FormData();
    // formData.append("wstoken", "44774588faa80db89fd429cb80ab2ac2");
    // formData.append("wsfunction", "local_participant_create_users");
    // formData.append("moodlewsrestformat", "json");
    // formData.append("fullname", fullName);
    // formData.append("email", email);
    // formData.append("mobile", mobile);
    // formData.append("password", password);

    if (!formData?.firstname) {
      toast.info("First name is required!");
    } else if (!formData?.lastname) {
      toast.info("Last name is required!");
    } else if (!formData?.email) {
      toast.info("Email is required!");
    } else if (!formData?.password) {
      toast.info("Password is required!");
    } else {
      const loginData = await loginuser(formData).then((res) => {
        toast.success("Signup successfull!");
        navigate("/login");
        // window.location.href = "/login";
      });
      // if (loginData.data.userfound) {
      //   window.location.href = "/checkout";
      //   setLoading(false);
      //   localStorage.setItem("loginData", JSON.stringify(loginData.data));
      //   localStorage.setItem(
      //     "fullname",
      //     formData?.firstname + " " + formData?.lastname
      //   );
      //   localStorage.setItem("password", formData?.password);
      //   setLoading(true);
      //   //   setFormOpen(false);
      //   toast.success("Successfully logged in");
      // } else {
      //   toast.error(loginData.message);
      //   setLoading(false);
      //   // setLogin(false);
      // }
    }
  };

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  //Access token decode
  useEffect(() => {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${profile?.access_token}`
      )
      .then(function (response) {
        // handle success
        setDecodedData(response?.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [profile]);

  // for google login
  useEffect(() => {
    if (decodedData) {
      const formData = new FormData();
      formData.append("wstoken", "5bfb4ca0218d42740673b4f6f8aa0db7");
      formData.append("moodlewsrestformat", "json");
      formData.append("wsfunction", "local_user_login_and_registration");
      formData.append("email", decodedData?.email);
      formData.append("firstname", decodedData?.given_name);
      formData.append("lastname", decodedData?.family_name);

      axios
        .post("http://rwtlms.in/acca/webservice/rest/server.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          window.location.href = "/checkout";
          localStorage.setItem("loginData", JSON.stringify(res?.data?.data));
          localStorage.setItem("username", res?.data?.data?.username);
          localStorage.setItem("password", res?.data?.data?.password);
          toast.success(res?.data?.message);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [decodedData]);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div
        className="bg-white p-8 rounded-lg w-full max-w-md"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            fontSize: "20px",
            fontWeight: "bolder",
            textAlign: "center",
            marginTop: "40px",
          }}
        >
          Sign Up Now
        </div> */}

        <div
          style={{
            width: "420px",
            // maxWidth: "480px",
            margin: "auto",
            border: "1px solid gray",
            padding: "40px",
            marginTop: "20px",
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "40px",
            borderRadius: "10px 0px 0px 10px",
          }}
        >
          <div
            style={{
              // textAlign: "center",
              fontSize: "20px",
              fontWeight: "700",
              color: "#242227",
              //   padding: "0px 0px 10px 0px",
            }}
          >
            <p>Register / Sign up</p>
          </div>

          {/* <div>
            <LoginSocialGoogle
              // isOnlyGetToken
              scope={"https://www.googleapis.com/auth/userinfo.email"}
              client_id={
                "978577839819-2pgqe46d7b2sngptjp8lv5onophe1qs1.apps.googleusercontent.com" ||
                ""
              }
              onLoginStart={onLoginStart}
              onResolve={({ provider, data }: IResolveParams) => {
                setProvider(provider);
                setProfile(data);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <button className="btn btn-outline-secondary w-100">
                <img
                  src="/google-logo.png"
                  height={"25px"}
                  width={"25px"}
                  style={{ marginRight: "5px" }}
                />
                Continue with Google
              </button>
            </LoginSocialGoogle>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              paddingTop: "10px",
            }}
          >
            <span
              style={{
                width: "150px",
                height: "1.5px",
                backgroundColor: "#999",
              }}
            ></span>
            <span
              style={{ fontSize: "17px", fontWeight: "500", color: "#777" }}
            >
              OR
            </span>
            <span
              style={{
                width: "150px",
                height: "1.5px",
                backgroundColor: "#999",
              }}
            ></span>
          </div> */}

          <form action="#" className="mt-3">
            <div style={{}}>
              <div>
                <div className="form-floating mb-3">
                  <input
                    onChange={(e) =>
                      setFormData((pre: any) => ({
                        ...pre,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    name="firstname"
                    id="firstname"
                    className="form-control"
                    placeholder="Full name11"
                  />
                  <label htmlFor="firstname">
                    First name <span>*</span>
                  </label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    onChange={(e) =>
                      setFormData((pre: any) => ({
                        ...pre,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    name="lastname"
                    id="lastname"
                    className="form-control"
                    placeholder="Last name"
                  />
                  <label htmlFor="lastname">
                    Last name <span>*</span>
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    onChange={(e) =>
                      setFormData((pre: any) => ({
                        ...pre,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Email Id"
                  />
                  <label htmlFor="email">
                    Email Id<span>*</span>
                  </label>
                </div>
                {/* <div className="form-floating mb-3">
                  <input
                    onChange={(e) =>
                      setFormData((pre) => ({
                        ...pre,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    name="mobile"
                    id="mobile"
                    className="form-control"
                    placeholder="Mobile nimber"
                  />
                  <label htmlFor="mobile">
                    Mobile<span>*</span>
                  </label>
                </div> */}
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    onChange={(e) =>
                      setFormData((pre: any) => ({
                        ...pre,
                        [e.target.name]: e.target.value,
                      }))
                    }
                    name="password"
                    id="password"
                    className="form-control"
                    placeholder="Enter Password"
                  />
                  <label htmlFor="password">
                    Password <span>*</span>
                  </label>
                </div>
              </div>

              <div className="col-lg-12 pt-3 text-end">
                {/* {loadData === true ? (
                        <button
                          type="button"
                          className="btn btn-dark btn-lg py-3 px-5 w-100"
                          onClick={() => feedbackForm()}
                          disabled
                        >
                          Submit Enquiry
                        </button>
                      ) : ( */}
                <button
                  type="button"
                  className="btn btn-dark btn-lg py-3 px-5 w-100"
                  onClick={() => signUp()}
                >
                  SIGN UP
                </button>
                {/* )} */}
              </div>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <span>
                  Already have an account?{" "}
                  <Link to={"/login"}>
                    {" "}
                    <span
                      style={{
                        fontSize: "17px",
                        color: "#4f2d7f",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      Login
                    </span>
                  </Link>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewSignUp;
