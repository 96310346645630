import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFooterData } from "../store/layout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Image from "../components/elements/Image";
import { submitFeedback } from "../actions/feedback";
import { toast } from "react-toastify";
import { fetchProgrameData } from "../store/programe";
import { enqueryFormOpen, enqueryId } from "../store/enqueryFormOpen";
import EnquireModal from "../components/pagesections/Shared/EnquireModal";
import EnquireSlug from "../pages/EnquireSlug";

const DefaultFooter = () => {
  const dispatch = useDispatch();
  const location2 = useLocation();
  const segment = location2.pathname.split("/")[2];
  const [scroll, setScroll] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [courseName, setCourseName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [qualification, setQualification] = useState("");
  const [location, setLocation] = useState("");
  const [hireFrom, setHireFrom] = useState("");
  const [feedback, setFeedback] = useState("");

  const [firstnameErr, setFirstnameErMsg] = useState("");
  const [lastnameErr, setLastnameErMsg] = useState("");
  const [companyErr, setCompanyErr] = useState("");
  const [emailErr, setEmailErMsg] = useState("");
  const [phErr, setPhErr] = useState("");
  const [designationErr, setdesignationErr] = useState("");
  const [qualificationErr, setqualificationErr] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const [msgEr, setErMsg] = useState("");

  const [loadData, setLoadData] = useState(false);
  const [enqueryModalOpen, setEnqueryModalOpen] = useState(false);
  const FromRToggleData = useSelector(
    (state: any) => state.enqueryToggle.formOpen
  );
  const enqId = useSelector((state: any) => state.enqueryToggle.enqId);
  const [formOpen, setFormOpen] = useState(false);
  const { id } = useParams();
  const [courseSlug, setCourseSlug] = useState(id);
  const navigate = useNavigate();
  const url = useLocation();
  const isLandingPage = url.pathname.split("/")[1];

  function capitalize(str: any) {
    return str.replace(/\b\w/g, function (char: string) {
      return char.toUpperCase();
    });
  }

  useEffect(() => {
    setFormOpen(FromRToggleData);
  }, [FromRToggleData]);

  const footerData = useSelector(
    (state: any) => state.layoutData.footerData.data
  );

  const programData = useSelector(
    (state: any) => state.programData.programData
  );

  useEffect(() => {
    dispatch(fetchFooterData());
    dispatch(fetchProgrameData(1000, 1, 0, 0));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY == 0);
    });
  }, []);

  useEffect(() => {
    id && setCourseName(id);
  }, [id]);

  useEffect(() => {
    enqId === null ? setCourseName("") : setCourseName(enqId);
    enqId === null ? setCourseSlug("") : setCourseSlug(enqId);
    // enqId && setCourseSlug(enqId);
  }, [enqId]);

  useEffect(() => {
    courseSlug && setCourseName(capitalize(courseSlug?.replace(/-/g, " ")));
  }, [courseSlug]);

  const feedbackForm = async () => {
    setLoadData(true);
    const formData = {
      AuthToken: "GTACADEMY-30-11-2022",
      Source: "gtacademy",
      FirstName: firstname,
      LastName: lastname,
      Course: courseName,
      Email: email,
      Textb1: company,
      MobileNumber: phone,
      Textb2: designation,
      Textb3: qualification,
      Field5: location,
      Field4: hireFrom,
      Feedback: feedback,
    };
    const formData2 = {
      "first-name": firstname,
      "last-name": lastname,
      "course-name": courseName,
      "your-email": email,
      "company-name": company,
      "your-mobile": phone,
      designation: designation,
      qualification: qualification,
      location: location,
      "hear-from": hireFrom,
      "your-message": feedback,
    };

    try {
      if (!firstname) {
        setFirstnameErMsg("First name is required!");
        // toast.error("First name is required!");
      }
      if (!lastname) {
        setLastnameErMsg("Last name is required!");
        // toast.error("Last name is required!");
      }
      if (!email) {
        setEmailErMsg("Email is required!");
        // toast.error("Email is required!");
      }
      if (!company) {
        setCompanyErr("Company name is required!");
        // toast.error("Company name is required!");
      }
      if (!phone) {
        setPhErr("Mobile number is required!");
        // toast.error("Mobile number is required!");
      }
      if (!feedback) {
        setErMsg("Enquiry message is required!");
        // toast.error("Enquery message is required!");
      }
      if (!designation) {
        setdesignationErr("Designation name is required!");
        // toast.error("Designation name is required!");
      }
      if (!qualification) {
        setqualificationErr("Your qualification is required!");
        // toast.error("Your qualification is required!");
      }
      if (!location) {
        setLocationErr("Your location is required!");
        // toast.error("Your location is required!");
      }

      if (
        firstname &&
        lastname &&
        email &&
        company &&
        phone &&
        feedback &&
        designation &&
        qualification &&
        location
      ) {
        const submitfeedback2 = await submitFeedback({
          formData: formData,
          formData2: formData2,
        });

        setLoadData(false);
        setFormOpen(!formOpen);
        toast.success("Your enquiry is submitted successfully!");
        setFirstname("");
        setLastname("");
        setCourseName("");
        setCompany("");
        setEmail("");
        setPhone("");
        setDesignation("");
        setQualification("");
        setLocation("");
        setHireFrom("");
        setFeedback("");

        setFirstnameErMsg("");
        setLastnameErMsg("");
        setPhErr("");
        setEmailErMsg("");
        setErMsg("");
        setdesignationErr("");
        setCompanyErr("");
        setLocationErr("");
        window.dataLayer.push({
          'event':'form_submission',
          'enhanced_conversion_data': {
            "email": email,   
            "phone_number": phone,
          }
        });
        navigate("/thankyou");
      } else {
        toast.error("Something went wrong, try later !");
      }

      // const submitfeedback = await submitFeedback(formData);

      // if (submitfeedback.status === "validation_failed") {
      //   toast.error(submitfeedback.message);
      //   if (Array.isArray(submitfeedback.invalid_fields)) {
      //     const firstName = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "first-name";
      //     });
      //     const lastName = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "last-name";
      //     });
      //     const email = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "your-email";
      //     });
      //     const phone = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "your-mobile";
      //     });
      //     const message = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "your-message";
      //     });
      //     if (firstName !== undefined) {
      //       setFirstnameErMsg(firstName.message);
      //     } else {
      //       setFirstnameErMsg("");
      //     }
      //     if (lastName !== undefined) {
      //       setLastnameErMsg(lastName.message);
      //     } else {
      //       setLastnameErMsg("");
      //     }
      //     if (email !== undefined) {
      //       setEmailErMsg(email.message);
      //     } else {
      //       setEmailErMsg("");
      //     }
      //     if (phone !== undefined) {
      //       setPhErr(phone.message);
      //     } else {
      //       setPhErr("");
      //     }
      //     if (message !== undefined) {
      //       setErMsg(message.message);
      //     } else {
      //       setErMsg("");
      //     }
      //   }
      // } else {
      //   setLoadData(false);
      //   setFormOpen(!formOpen);
      //   toast.success(submitfeedback.message);
      //   setFirstname("");
      //   setLastname("");
      //   setCourseName("");
      //   setCompany("");
      //   setEmail("");
      //   setPhone("");
      //   setDesignation("");
      //   setQualification("");
      //   setLocation("");
      //   setHireFrom("");
      //   setFeedback("");
      //   setFirstnameErMsg("");
      //   setLastnameErMsg("");
      //   setPhErr("");
      //   setEmailErMsg("");
      //   setErMsg("");
      //   navigate("/thankyou");
      // }
      setLoadData(false);
    } catch (error: any) {
      // console.log(error.response.data);
    }
  };

  const handleClose = () => {
    dispatch(enqueryFormOpen(!FromRToggleData));
  };

  const [scrollYPosition, setScrollYPosition] = useState(0);

  const handleScroll = () => {
    const newScrollYPosition = window.pageYOffset;
    setScrollYPosition(newScrollYPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <footer className="site-footer">
        <>
          <div className="footer-inforamtion">
            <div className="container-xl">
              <div className="row">
                {/* ----------------------Logo and social media sec---------------------- */}
                <div className="col-md-6 border-end">
                  <div className="footer-about">
                    <Link to="/">
                      <Image
                        src={footerData.footer_logo}
                        alt={""}
                        width={"249"}
                        height={"47"}
                        effect={"blur"}
                        classes={"img-fluid mb-3"}
                      />
                    </Link>
                    {isLandingPage !== "lp" && (
                      <>
                        <p>{footerData.footer_about}</p>
                        <div className="footer__social mt-4">
                          <ul>
                            <li>
                              <Link
                                to={footerData.social_links?.facebook || ""}
                                target="_blank"
                                rel="nofollow"
                              >
                                <i className="fa-brands fa-facebook-f"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={footerData.social_links?.twitter || ""}
                                target="_blank"
                                rel="nofollow"
                              >
                                <i className="fa-brands fa-x-twitter"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={footerData.social_links?.youtube || ""}
                                target="_blank"
                                rel="nofollow"
                              >
                                <i className="fa-brands fa-youtube"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={footerData.social_links?.linkedin || ""}
                                target="_blank"
                                rel="nofollow"
                              >
                                <i className="fa-brands fa-linkedin"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={footerData.social_links?.instagram || ""}
                                target="_blank"
                                rel="nofollow"
                              >
                                <i className="fa-brands fa-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* ----------------------Info sec---------------------- */}
                {isLandingPage !== "lp" && (
                  <>
                    <div className="col-md-3 border-end">
                      <div className="footer-links">
                        <h6 className="section-heading">INFO</h6>
                        <ul>
                          {footerData?.info_menu &&
                            footerData?.info_menu.map((item: any, i: any) => (
                              <li key={i + item.title}>
                                <Link to={item.url}>{item.title}</Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                {/* <div className="col-md-4 border-end">
                <div className="footer-links pb-3">
                  <h6 className="section-heading">PROGRAMS</h6>
                  <ul>
                    {footerData?.program_menu &&
                      footerData?.program_menu.map((item: any, i: any) => (
                        <li key={i}>
                          <Link to={`/program-detail/${item.slug}`}>
                            {item.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div> */}
                {/* ----------------------Help sec---------------------- */}
                {isLandingPage !== "lp" && (
                  <div className="col-md-3">
                    <div className="footer-links">
                      <h6 className="section-heading">HELP</h6>
                      <ul>
                        {footerData?.help_menu &&
                          footerData?.help_menu.map((item: any, i: any) => (
                            <li key={i + item.title}>
                              <Link to={item.url}>{item.title}</Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {isLandingPage !== "lp" && (
            <>
              <div className="container-xl">
                <hr />
              </div>

              <div className="footer-address pt-5 pb-5">
                <div className="container-xl">
                  <div className="row">
                    {footerData?.directors &&
                      footerData?.directors.map((item: any, i: any) => (
                        <div
                          className="col-md-3 col-12 mb-2"
                          key={i + item.name}
                        >
                          <span className="text-white">
                            {item.name}
                            <br />
                            {item.designation}
                          </span>
                          <br />
                          <span className="links mt-1 d-block">
                            {item.phone_no && (
                              <>
                                {" "}
                                M:{" "}
                                <Link to={"tel:" + item.phone_no}>
                                  {item.phone_no}
                                </Link>{" "}
                                <br />
                              </>
                            )}
                            E:{" "}
                            <Link to={"mailto:" + item.email}>
                              {item.email}
                            </Link>
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="container-xl">
                <hr />
              </div>
            </>
          )}
        </>

        <div className="footer-copyright pb-4 pt-3">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-sm-auto col-12">
                <p className="mb-0">{footerData.copyright}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <Link
        id="back-to-top"
        to="#"
        className="scroll-up back-to-top shadow rounded"
        role="button"
      >
        <i className="fa-solid fa-angle-up"></i>
      </Link> */}

      {scrollYPosition > 200 && (
        <div className={"mobile-fx d-md-none d-lg-none  text-center bg-white"}>
          <div className="row d-flex align-items-center">
            <div
              className="col-6 box-text "
              onClick={() => {
                // setFormOpen(!formOpen);
                setEnqueryModalOpen(!enqueryModalOpen);
                handleScroll();
              }}
            >
              {" "}
              <Link to="#" className="text-white d-block">
                Enquire Now{" "}
              </Link>{" "}
            </div>
            <div className="col-6 box-text box-text2 ">
              {" "}
              <Link
                to="//api.whatsapp.com/send?phone=8527272863"
                className="text-white d-block"
              >
                <span style={{ marginRight: 5 }}>
                  <i className="fa-brands fa-whatsapp"></i>
                </span>
                Chat With Us
              </Link>{" "}
            </div>
          </div>
        </div>
      )}

      <div
        className={
          "stickyFormWrapper " +
          (scroll ? " isVisible" : "isVisible") +
          (formOpen ? " formIsOpen" : "")
        }
      >
        <div className="Fields-wrapper">
          <button
            className="fixed-sticker"
            type="button"
            onClick={() => {
              setFormOpen(!formOpen);
            }}
          >
            <img
              src={window.location.origin + "/images/enquire-us-now.jpg"}
              alt=""
              style={{ width: "32px", height: "146px" }}
            />
          </button>

          <h4>
            Enquire Now
            <button
              className="closestickyform"
              type="button"
              // onClick={() => dispatch(enqueryFormOpen(!FromRToggleData))}
              onClick={handleClose}
            >
              Close<i className="fa fa-close ms-1"></i>
            </button>
          </h4>

          <div className="enquire-now-sideform">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="first-name"
                id="first-name"
                placeholder="First Name*"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                required
              />
              {firstnameErr && <p style={{ color: "red" }}>{firstnameErr}</p>}
              <label htmlFor="first-name">
                First Name <span>*</span>
              </label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="last-name"
                id="last-name"
                placeholder="Last Name*"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
              />
              {lastnameErr && <p style={{ color: "red" }}>{lastnameErr}</p>}
              <label htmlFor="last-name">
                Last Name <span>*</span>
              </label>
            </div>

            <div className="form-floating mb-3">
              <select
                className="form-select"
                id="course-name"
                aria-label="How Did You Hear About Us?"
                onChange={(e) => setCourseName(e.target.value)}
                defaultValue={courseName}
                required
                disabled={courseSlug ? true : false}
              >
                <option>Select One Course</option>
                {Array.isArray(programData) &&
                  programData.map(
                    (m: any, r: any) =>
                      courseSlug ? (
                        <>
                          <option
                            style={{ fontWeight: "600", color: "black" }}
                            selected={true}
                            value={
                              courseSlug &&
                              capitalize(courseSlug?.replace(/-/g, " "))
                            }
                            key={r}
                          >
                            {id && capitalize(courseSlug?.replace(/-/g, " "))}
                          </option>
                        </>
                      ) : (
                        <option value={m?.title} key={r}>
                          {m?.title}
                        </option>
                      )

                    // <option
                    //   value={m.program_id}
                    //   selected={
                    //     courseSlug && courseSlug === m.slug ? true : false
                    //   }
                    //   // disabled={courseSlug ? true : false}
                    //   key={r}
                    // >
                    //   {m.title}
                    // </option>

                    // <option value={m.slug} key={r}>{m.title}</option>
                    // id && id === m.slug ? <option style={{fontWeight:"600",color:"black"}} value={m.slug} key={r}>{m.title}</option> : <option disabled value={m.slug} key={r}>{m.title}</option>
                  )}
              </select>
              <label htmlFor="your-mobile">
                Course<span></span>
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                name="your-email"
                id="your-email"
                placeholder="Email ID*"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {emailErr && <p style={{ color: "red" }}>{emailErr}</p>}
              <label htmlFor="your-email">
                Email ID <span>*</span>
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                name="your-company"
                id="your-company"
                placeholder="Company*"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                required
              />
              {companyErr && <p style={{ color: "red" }}>{companyErr}</p>}
              <label htmlFor="your-company">
                Company <span>*</span>
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="your-mobile"
                name="your-mobile"
                placeholder="Mobile No.*"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              {phErr && <p style={{ color: "red" }}>{phErr}</p>}
              <label htmlFor="your-mobile">
                Mobile No. <span>*</span>
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="your-designation"
                name="your-designation"
                placeholder="Designation*"
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
                required
              />
              {designationErr && (
                <p style={{ color: "red" }}>{designationErr}</p>
              )}
              <label htmlFor="your-designation">
                Designation. <span>*</span>
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="your-qualification"
                name="your-qualification"
                placeholder="Qualification*"
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
                required
              />
              {qualificationErr && (
                <p style={{ color: "red" }}>{qualificationErr}</p>
              )}
              <label htmlFor="your-designation">
                Qualification. <span>*</span>
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="your-location"
                name="your-location"
                placeholder="Location*"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
              {locationErr && <p style={{ color: "red" }}>{locationErr}</p>}
              <label htmlFor="your-location">
                Location. <span>*</span>
              </label>
            </div>

            <div className="form-floating mb-3">
              <select
                className="form-select"
                id="hearfrom"
                aria-label="How Did You Hear About Us?"
                onChange={(e) => setHireFrom(e.target.value)}
                required
              >
                <option value="">Select one source</option>
                <option value="CA club">CA Club</option>
                <option value="Google">Google</option>
                <option value="Facebook">Facebook</option>
                <option value="Linkedin">Linkedin</option>
                <option value="Email">Email</option>
                <option value="IIM">IIM</option>
                <option value="Naukri">Naukri</option>
                <option value="Study at home">Study at home</option>
                <option value="Others">Others</option>
              </select>
              <label htmlFor="your-mobile">
                How Did You Hear About Us?<span></span>
              </label>
            </div>

            <div className="form-floating">
              <textarea
                className="form-control"
                placeholder="Enquiry Message"
                id="your-message"
                name="your-message"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                required
              ></textarea>
              {msgEr && <p style={{ color: "red" }}>{msgEr}</p>}
              <label htmlFor="your-message">Enquiry Message</label>
            </div>
          </div>
          <div className="col-lg-12 pt-3 text-end">
            {loadData === false ? (
              <button
                type="submit"
                className="btn btn-dark btn-lg py-3 px-5 w-100"
                onClick={() => feedbackForm()}
              >
                Submit Enquiry
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-dark btn-lg py-3 px-5 w-100"
                onClick={() => feedbackForm()}
                disabled
              >
                Submit Enquiry
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className={"sticky-whatsapp " + (scroll ? "isVisible" : "isVisible")}
      >
        <a
          href="//api.whatsapp.com/send?phone=8527272863"
          className="btn-whatsapp"
        >
          <i className="fab fa-whatsapp"></i>
        </a>
      </div>

      <EnquireModal
        setFormOpen={setEnqueryModalOpen}
        formOpen={enqueryModalOpen}
        title="yes"
        initial={true}
      />
    </>
  );
};

export default DefaultFooter;
