import axios from "axios";
import BASE_URL, { THIRD_PARTY_BASE_URL,leadsURL } from "../config/host";

export const submitFeedback = async (body: any) => {
  // program_id=MjQ2

  const details = await axios({
    method: "post",
    url: THIRD_PARTY_BASE_URL + "/api/SaveRequest",
    // url:
    //   BASE_URL +
    //   "/index.php/wp-json/contact-form-7/v1/contact-forms/135/feedback",
    data: body?.formData,
    headers: { "Content-Type": "multipart/form-data" },
  });

  const details2 = await axios({
    method: "post",
    // url: THIRD_PARTY_BASE_URL + "/api/SaveRequest",
    url:
      BASE_URL +
      "/index.php/wp-json/contact-form-7/v1/contact-forms/135/feedback",
    data: body?.formData2,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const submitContactUs = async (body: any) => {
  // program_id=MjQ2
  const details = await axios({
    method: "post",
    url:
      BASE_URL +
      "/index.php/wp-json/contact-form-7/v1/contact-forms/3367/feedback",
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });

  // https://api.learningt.com/index.php/wp-json/contact-form-7/v1/contact-forms/3367/feedback
};

export const submitBrochure = async (body: any) => {
  // program_id=MjQ2

  const details = await axios({
    method: "post",
    url: "https://thirdpartyapi.extraaedge.com/api/SaveRequest",
    // url:
    //   BASE_URL +
    //   "/index.php/wp-json/contact-form-7/v1/contact-forms/2594/feedback",
    data: body?.formData,
    headers: { "Content-Type": "multipart/form-data" },
  });

  const details2 = await axios({
    method: "post",
    // url: "https://thirdpartyapi.extraaedge.com/api/SaveRequest",
    url:
      BASE_URL +
      "/index.php/wp-json/contact-form-7/v1/contact-forms/2594/feedback",
    data: body?.formData2,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return details.data;
};

export const submitFeedback2 = async (body: any) => {
  // program_id=MjQ2
  // 9f7d85d
  const details = await axios({
    method: "post",
    url: "https://thirdpartyapi.extraaedge.com/api/SaveRequest",
    // url:
    //   BASE_URL +
    //   "/index.php/wp-json/contact-form-7/v1/contact-forms/2494/feedback",
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });

  const details2 = await axios({
    method: "post",

    url:
      BASE_URL +
      "/index.php/wp-json/contact-form-7/v1/contact-forms/2494/feedback",
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return details2.data;
};

export const submitFeedbackLandingPage = async (body: any) => {
  // program_id=MjQ2

  const details2 = await axios({
    method: "post",
    // url: BASE_URL + "/index.php/wp-json/contact-form-7/v1/contact-forms/2348/feedback",
    // url: BASE_URL + "/index.php/wp-json/contact-form-7/v1/contact-forms/2340/feedback",
    url: "https://thirdpartyapi.extraaedge.com/api/SaveRequest",
    //  url: BASE_URL +
    //   "/index.php/wp-json/contact-form-7/v1/contact-forms/2443/feedback",
    data: body?.formData,
    headers: { "Content-Type": "multipart/form-data" },
  });

  const details = await axios({
    method: "post",
    // url: BASE_URL + "/index.php/wp-json/contact-form-7/v1/contact-forms/2348/feedback",
    // url: BASE_URL + "/index.php/wp-json/contact-form-7/v1/contact-forms/2340/feedback",
    // url: "https://thirdpartyapi.extraaedge.com/api/SaveRequest",
    url:
      BASE_URL +
      "/index.php/wp-json/contact-form-7/v1/contact-forms/2443/feedback",
    data: body?.formData2,
    headers: { "Content-Type": "multipart/form-data" },
  });

  // return details.data;
};

export const submitNewsLetter = async (body: any) => {
  const details = await axios({
    method: "post",
    url: BASE_URL + "/index.php/wp-json/subscribe/newsletter",
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return details.data;
};

export const leadAPICall = async () => {
  
  const details = await axios({
    method: "post",
    url: leadsURL,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return details.data;
};
