import { url } from "inspector";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  submitFeedback2,
  submitFeedbackLandingPage,
} from "../actions/feedback";
import { toast } from "react-toastify";
import axios from "axios";
import BASE_URL from "../config/host";
import ProgrameListLoader from "../shared/ProgrameListLoader";
import ProgrameDetailLoader from "../shared/ProgrameDetailLoader";
import Spinner from "../shared/Spinner";
import HomeLoader from "../shared/HomeLoader";
import { fetchLandingFDDetails, fetchLandingPageDetails } from "../store/page";
import { Rating } from "react-simple-star-rating";

const FeedbackForm = () => {
  const [loadData, setloadData] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [course, setcourse] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [trainerName, setTrainerName] = useState("");
  const [comment, setComment] = useState("");
  const [designation, setdesignation] = useState("");
  const [qualification, setqualification] = useState("");
  const [location, setlocation] = useState("");
  const [hearFrom, sethearFrom] = useState("");

  const [firstnameErr, setFirstnameErMsg] = useState("");
  const [lastnameErr, setLastnameErMsg] = useState("");
  const [emailErr, setEmailErMsg] = useState("");
  const [phErr, setPhErr] = useState("");
  const navigate = useNavigate();
  const [programData, setprogramData] = useState([]);
  const [slugs, setSlugs] = useState<string[]>([]);
  const [flag, setflag] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [bannerData, setbannerData] = useState<any>(null);
  const [loading, setloading] = useState(true);
  const [ratingOne, setRatingOne] = useState(0);
  const [ratingTwo, setRatingTwo] = useState(0);
  const [ratingThree, setRatingThree] = useState(0);
  const [ratingFour, setRatingFour] = useState(0);
  const [ratingFive, setRatingFive] = useState(0);
  const [ratingSix, setRatingSix] = useState(0);
  const [ratingSeven, setRatingSeven] = useState(0);
  const [dateOfTraining, setDateOfTraining] = useState("");
  const dispatch = useDispatch<any>();

  const { id } = useParams();
  const courseUrl = useSelector(
    (state: any) => state.pageData.landingPageData.courseUrl
  );

  const params =
    id &&
    id.replace(/-/g, " ").replace(/\b\w/g, (match) => match.toUpperCase());

  // const backgroundImageStyle = {
  //   backgroundImage:
  //     "url(https://rwtpl.com/demov/gt/wp-content/uploads/2023/01/bg-program.jpg)",
  // };
  // const backgroundImageStyle = {
  //   backgroundImage: bannerData
  //     ? `url(${bannerData?.featured_image})`
  //     : "url(https://rwtpl.com/demov/gt/wp-content/uploads/2023/01/bg-program.jpg)",
  // };

  useEffect(() => {
    dispatch(fetchLandingFDDetails(id));
  }, [id]);

  const backgroundImageStyle = {
    backgroundImage: bannerData && `url(${bannerData?.featured_image})`,
  };

  useEffect(() => {
    axios
      .get(BASE_URL + "/index.php/wp-json/landing_fd/detail?slug=" + id)
      .then((response) => {
        setbannerData(response.data.data.data);
        setloading(false); // Set loading to false when data is fetched
      })
      .catch((err) => {
        setloading(false); // Set loading to false in case of an error
      });
  }, []);

  useEffect(() => {
    axios
      .get(BASE_URL + "/index.php/wp-json/landing_fd/pages")
      .then((response) => {
        setprogramData(response.data.data.data);
      });
  }, []);

  const handleSubmit = async () => {
    setloadData(true);
    const formData = {
      "first-name": firstName,
      "last-name": lastName,
      "course-name": course,
      "your-email": email,
      rating_one: ratingOne,
      rating_two: ratingTwo,
      rating_three: ratingThree,
      rating_four: ratingFour,
      rating_five: ratingFive,
      rating_six: ratingSix,
      rating_seven: ratingSeven,
      "trainer-name": trainerName,
      "date-of-training": dateOfTraining,
      comment: comment,
    };

    try {
      const submitfeedback: any = await submitFeedback2(formData);

      if (submitfeedback.status === "validation_failed") {
        toast.error(submitfeedback.message);
        if (Array.isArray(submitfeedback.invalid_fields)) {
          const firstName = submitfeedback.invalid_fields.find((item: any) => {
            return item.field === "first-name";
          });
          const lastName = submitfeedback.invalid_fields.find((item: any) => {
            return item.field === "last-name";
          });
          const email = submitfeedback.invalid_fields.find((item: any) => {
            return item.field === "your-email";
          });
          const comment = submitfeedback.invalid_fields.find((item: any) => {
            return item.field === "comment";
          });
          const trainerName = submitfeedback.invalid_fields.find(
            (item: any) => {
              return item.field === "trainer-name";
            }
          );
          const dateOfTraining = submitfeedback.invalid_fields.find(
            (item: any) => {
              return item.field === "date-of-training";
            }
          );
          if (firstName !== undefined) {
            setFirstnameErMsg(firstName.message);
          } else {
            setFirstnameErMsg("");
          }
          if (lastName !== undefined) {
            setLastnameErMsg(lastName.message);
          } else {
            setLastnameErMsg("");
          }
          if (email !== undefined) {
            setEmailErMsg(email.message);
          } else {
            setEmailErMsg("");
          }
          if (comment !== undefined) {
            setPhErr(comment.message);
          } else {
            setPhErr("");
          }
        }
      } else if (submitfeedback.status === "mail_failed") {
        toast.error(submitfeedback.message);
      } else {
        setloadData(false);
        toast.success(submitfeedback.message);
        setfirstName("");
        setlastName("");
        setcourse("");
        setTrainerName("");
        setComment("");
        setemail("");
        setDateOfTraining("");
        setRatingOne(0);
        setRatingTwo(0);
        setRatingThree(0);
        setRatingFour(0);
        setRatingFive(0);
        setRatingSix(0);
        setRatingSeven(0);
        navigate("/thankyou");
      }
      // if (submitfeedback.status !== "validation_failed") {
      //   setloadData(false);
      //   setfirstName("");
      //   setlastName("");
      //   setcourse("");
      //   setTrainerName("");
      //   setComment("");
      //   setemail("");
      //   setDateOfTraining("");
      //   setRatingOne(0);
      //   setRatingTwo(0);
      //   setRatingThree(0);
      //   setRatingFour(0);
      //   setRatingFive(0);
      //   setRatingSix(0);
      //   setRatingSeven(0);
      //   navigate("/thankyou");
      // }
      setloadData(false);
    } catch (error: any) {
      console.log(error?.response?.data);
    }
  };

  // useEffect(() => {
  //   if (Array.isArray(programData) && programData.length > 0) {
  //     const newSlugs = programData.map((elm: any) => elm.slug);
  //     setSlugs((prevSlugs) => [...prevSlugs, ...newSlugs]);
  //   }
  // }, [programData]);

  // console.log(slugs,"slugs")
  // useEffect(()=>{
  //   const x:any= id && slugs.length>0 && slugs.includes(id)
  //   console.log(x,"xx")
  //   // if(x===true){navigate("/not_found")}
  // },[id])

  // useEffect(()=>{
  //   flag === false && navigate("/not_found")
  // },[flag])
  //console.log(id,"id")

  useEffect(() => {
    // let Slugdata:any = [];
    let xxx: any =
      Array.isArray(programData) &&
      programData.length > 0 &&
      programData.map((elm: any, index) => {
        return elm.slug;
      });

    //   //console.log(id,'id');
    if (Array.isArray(xxx) && !xxx.includes(id)) {
      navigate("/not_found");
      setDisabled(false);
    } else {
      id && setcourse(id);
      setDisabled(true);
    }
  }, [programData.length > 0, id]);

  const handleRating1 = (rate: any) => {
    setRatingOne(rate);
  };
  const handleRating2 = (rate: any) => {
    setRatingTwo(rate);
  };
  const handleRating3 = (rate: any) => {
    setRatingThree(rate);
  };
  const handleRating4 = (rate: any) => {
    setRatingFour(rate);
  };
  const handleRating5 = (rate: any) => {
    setRatingFive(rate);
  };
  const handleRating6 = (rate: any) => {
    setRatingSix(rate);
  };
  const handleRating7 = (rate: any) => {
    setRatingSeven(rate);
  };

  return (
    <div>
      {/* <!-- INNER BANNER --> */}

      <section
        className="inner-banner-top Enquire"
        // style={backgroundImageStyle}
      >
        <img src={bannerData && bannerData?.featured_image} />
        {/* <div className="container customwid h-100">
          <div className="row h-100">
            <div className="col-xl-12">
              <div className="gt-breadcrumb mt-3">
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      Enquire Now
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="main-content">
              <h1 className="banner__title ">Enquire for</h1>
              <p className="mt-2">ACCA Qualification</p>
              {course && <p className="mt-2">{course}</p>}
              {!course && id && params && <p className="mt-2">{params}</p>}
            </div>
          </div>
        </div> */}
      </section>

      <div className="Contact_page  sec-spacing light-bg">
        <div className="container customwid">
          <h2 className="text-center all_heading mb-4">Feedback Form</h2>
          <div className="form-contact-pg-container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="left-side-form cor_popup">
                  <form method="post" action="#" id="email-form" className="">
                    <div className="enquire-now-sideform">
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="first-name"
                            id="first-name"
                            placeholder="First Name*"
                            value={firstName}
                            onChange={(e) => setfirstName(e.target.value)}
                            required
                          />
                          {firstnameErr && (
                            <p style={{ color: "red" }}>{firstnameErr}</p>
                          )}
                          <label htmlFor="first-name">
                            First Name <span>*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="last-name"
                            id="last-name"
                            value={lastName}
                            onChange={(e) => setlastName(e.target.value)}
                            placeholder="Last Name*"
                            required
                          />
                          {lastnameErr && (
                            <p style={{ color: "red" }}>{lastnameErr}</p>
                          )}
                          <label htmlFor="last-name">
                            Last Name <span>*</span>
                          </label>
                        </div>
                      </div>

                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="form-floating mb-3">
                          <select
                            className="form-select"
                            id="course-name"
                            aria-label="How Did You Hear About Us?"
                            onChange={(e) => setcourse(e.target.value)}
                            defaultValue={id && id}
                            disabled={isDisabled}
                            required
                          >
                            <option value="">Select one course</option>
                            {Array.isArray(programData) &&
                              programData.map((m: any, r: any) => (
                                <option
                                  value={m.slug}
                                  selected={id && id === m.slug ? true : false}
                                  disabled={id && id === m.slug ? true : false}
                                  key={r}
                                >
                                  {m.title}
                                </option>
                              ))}
                          </select>
                          <label htmlFor="your-mobile">
                            Course<span></span>
                          </label>
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            name="your-email"
                            id="your-email"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            placeholder="Email ID*"
                            required
                          />
                          {emailErr && (
                            <p style={{ color: "red" }}>{emailErr}</p>
                          )}
                          <label htmlFor="your-email">
                            Email ID <span>*</span>
                          </label>
                        </div>
                      </div>

                      {/* ---------------Ratings--------------- */}
                      <div>
                        <p>
                          Helped in relating the content to my job context -
                          covered practical aspects along with the theory
                        </p>
                        <div style={{  }}>
                          <Rating
                            onClick={handleRating1}
                            size={20}
                            // style={{ display: "flex", gap: "10px" }}
                            transition
                            allowFraction
                            // showTooltip
                            // tooltipArray={tooltipArray}
                            // fillColorArray={fillColorArray}
                          />
                        </div>

                        <p style={{ marginTop: "10px" }}>
                          How was the pace of the training?
                        </p>
                        <div style={{ marginTop: "0px" }}>
                          <Rating
                            onClick={handleRating2}
                            size={20}
                            transition
                            allowFraction
                            // showTooltip
                            // tooltipArray={tooltipArray}
                            // fillColorArray={fillColorArray}
                          />
                        </div>

                        <p style={{ marginTop: "0px" }}>
                          The speaker’s resolution of the queries raised during
                          the session
                        </p>
                        <div style={{ marginTop: "0px" }}>
                          <Rating
                            onClick={handleRating3}
                            size={20}
                            transition
                            allowFraction
                            // showTooltip
                            // tooltipArray={tooltipArray}
                            // fillColorArray={fillColorArray}
                          />
                        </div>

                        <p style={{ marginTop: "10px" }}>
                          The speaker’s interaction with the participant as and
                          when required
                        </p>
                        <div style={{ marginTop: "0px" }}>
                          <Rating
                            onClick={handleRating4}
                            size={20}
                            transition
                            allowFraction
                            // showTooltip
                            // tooltipArray={tooltipArray}
                            // fillColorArray={fillColorArray}
                          />
                        </div>

                        <p style={{ marginTop: "10px" }}>
                          Information was presented in a clear, logical and
                          structured manner
                        </p>
                        <div style={{ marginTop: "0px" }}>
                          <Rating
                            onClick={handleRating5}
                            size={20}
                            transition
                            allowFraction
                            // showTooltip
                            // tooltipArray={tooltipArray}
                            // fillColorArray={fillColorArray}
                          />
                        </div>

                        <p style={{ marginTop: "10px" }}>
                          Speaker’s command over the topic and the presentation
                        </p>
                        <div style={{ marginTop: "0px" }}>
                          <Rating
                            onClick={handleRating6}
                            size={20}
                            transition
                            allowFraction
                            // showTooltip
                            // tooltipArray={tooltipArray}
                            // fillColorArray={fillColorArray}
                          />
                        </div>

                        <p style={{ marginTop: "10px" }}>
                          Speaker’s communication of the concepts
                        </p>
                        <div style={{ marginTop: "0px" }}>
                          <Rating
                            onClick={handleRating7}
                            size={20}
                            transition
                            allowFraction
                            // showTooltip
                            // tooltipArray={tooltipArray}
                            // fillColorArray={fillColorArray}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          marginTop: "20px",
                        }}
                      >
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="trainer-name"
                            id="trainer-name"
                            value={trainerName}
                            onChange={(e) => setTrainerName(e.target.value)}
                            placeholder="Enter Trainer Name*"
                            required
                          />
                          {emailErr && (
                            <p style={{ color: "red" }}>{emailErr}</p>
                          )}
                          <label htmlFor="trainer-name">
                            Trainer Name <span>*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="date-of-training"
                            id="date-of-training"
                            value={dateOfTraining}
                            onChange={(e) => setDateOfTraining(e.target.value)}
                            placeholder="Date of Training*"
                            required
                          />
                          {emailErr && (
                            <p style={{ color: "red" }}>{emailErr}</p>
                          )}
                          <label htmlFor="date-of-training">
                            Date of Training (dd/mm/yyyy) <span>*</span>
                          </label>
                        </div>
                      </div>

                      <div className="form-floating mb-3">
                        <textarea
                          // type="text"
                          className="form-control"
                          name="comment"
                          id="comment"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          placeholder="Write youre comment ..."
                          required
                        />
                        {emailErr && <p style={{ color: "red" }}>{emailErr}</p>}
                        <label htmlFor="comment">
                          Comment If Any <span>*</span>
                        </label>
                      </div>

                      <div className="col-lg-12 pt-3 text-end">
                        <button
                          className="btn btn-dark btn-lg py-3 px-5 w-100"
                          type="button"
                          id="submit"
                          name="submit-form"
                          onClick={handleSubmit}
                          disabled={loadData ? true : false}
                        >
                          Submit Form
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackForm;
